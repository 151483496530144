.product-card {
  min-width: 300px;
  width: 80%;
  max-width: 350px;
  height: 375px;
  cursor: pointer;

  @include breakpoint-up("medium") {
    max-width: 400px;
    height: 450px;
  }
  @include breakpoint-up("large") {
    max-width: 450px;
    height: 550px;
  }
}

.product-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  box-shadow: $default-shadow;
  overflow: hidden;

  .slider-card-img {
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 100%;
    }
  }

  .slider-card-info {
    width: 100%;
    min-height: 100px;
    height: 20%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $bg-blur;

    h2 {
      width: 35%;
      margin: 1rem 0 0.5rem 1.5rem;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.5rem;
      color: $white;
    }
  }
}
