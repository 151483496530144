.btn {
  display: block;
  cursor: pointer;
}
.btn-primary {
  width: 300px;
  height: 48px;
  margin: 1rem auto;
  background-color: $lightBlue;
  border: none;
  border-radius: 0.5rem;
  transition: $t-150;
  color: $white;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 500;

  a {
    color: $white;
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;
  }

  &:hover {
    background-color: #42bec2;
  }
}
.btn-nav {
  background-color: transparent;
  border: none;
  transition: $t-150;

  &:hover {
    svg {
      width: 100%;
      height: 100%;

      g {
        line,
        path {
          stroke: $lightBlue;

          fill: $lightBlue;
        }
      }
    }
  }
}
.btn-slide-container {
  width: 48px;
  height: 96px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: $white;
  color: $lightBlue;
  font-size: 12px;
  font-weight: 700;
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);

  @include breakpoint-up("medium") {
    height: 48px;
    transform: none;
    position: relative;
    background-color: $lightBlue;
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 4px 0px $lightBlue;
    color: $white;
    transition: $t-150;

    &:hover {
      background-color: $white;
      color: $lightBlue;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    }
  }

  &.left {
    left: 0;
    border-radius: 0 0.5rem 0.5rem 0;
  }
  &.right {
    right: 0;
    border-radius: 0.5rem 0 0 0.5rem;
  }
}
.btn-share {
  width: 296px;
  height: 48px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  font-family: $font-primary;
  font-weight: 500;
  font-size: 14px;
  color: $white;
  border: none;
  border-radius: 0.5rem;
  transition: $t-150;

  &:hover {
    transform: scale(0.95);
  }

  &.twitter {
    background-color: #0da1f2;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
  &.facebook {
    background-color: #4267b2;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
}
.beneficio-buttons-wrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;

  @include breakpoint-up("medium") {
    flex-flow: row-reverse;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 1.5rem;
    right: 3rem;
  }
}
.btn-beneficio {
  width: 328px;
  height: 48px;
  margin: 0 auto 1rem auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  font-family: $font-primary;
  font-size: 14px;
  font-weight: 500;
  color: $lightBlue;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  transition: $t-150;

  a {
    font-family: $font-primary;
    font-size: 14px;
    font-weight: 500;
    color: $lightBlue;
  }

  &:hover {
    background-color: #42bec2;
    color: $white;
    border: 1px solid #42bec2;

    a {
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      color: $white;
    }
  }

  @include breakpoint-up("medium") {
    margin: 0;
    background-color: $lightBlue;
    border-radius: 0.5rem;
    color: $white;

    a {
      font-family: $font-primary;
      font-size: 14px;
      font-weight: 500;
      color: $white;
    }

    &:hover {
      &:hover {
        background-color: $white;
        color: $lightBlue;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);

        a {
          font-family: $font-primary;
          font-size: 14px;
          font-weight: 500;
          color: $lightBlue;
        }
      }
    }
  }
}
.btn-beneficio-scroll {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  transition: $t-150;

  &:hover {
    color: $lightBlue;
  }
  @include breakpoint-up("medium") {
    background-color: $lightBlue;
    border-radius: 0.5rem;
    color: white;

    &:hover {
      background-color: $white;
      color: $lightBlue;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
    }
  }
}
.mood-buttons-wrapper {
  @extend .beneficio-buttons-wrapper;
}
.btn-mood {
  @extend .btn-primary;
  @include breakpoint-up("medium") {
    margin: 0;

    &:hover {
      background-color: $white;
      color: $lightBlue;
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
      a {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 500;
        color: $lightBlue;
      }
    }
  }
}
