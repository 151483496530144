.hidden {
  display: none !important;
}
.show {
  display: block !important;
}
.w-100 {
  width: 100% !important;
}
.bg-lightBlue {
  background: linear-gradient(
    to bottom,
    transparent,
    transparent 80%,
    $lightBlue 80%,
    $lightBlue 100%
  );

  &.bg-large {
    @include breakpoint-up("large") {
      background: linear-gradient(
        to bottom,
        transparent,
        transparent 40%,
        $lightBlue 40%,
        $lightBlue 100%
      );
    }
  }
}
.text-lightBlue {
  color: $lightBlue !important;
}
.text-disabled {
  color: $disabled !important;
}
.link-active {
  @extend .text-lightBlue;

  & a {
    @extend .text-lightBlue;
  }
}
.no-scroll {
  overflow: hidden !important;
}
.title-description {
  max-width: 500px;
  margin: 0 2.5rem;
  font-size: 14px;
  font-weight: 500;

  @include breakpoint-up("medium") {
    font-size: 16px;
  }
  @include breakpoint-up("large") {
    margin: 0 5rem;
    font-size: 28px;
  }
}
.title-header {
  max-width: 500px;
  margin: 0 2.5rem;
  font-weight: 700;
  font-size: 2rem;
  @include breakpoint-up("medium") {
    font-size: 40px;
  }
  @include breakpoint-up("large") {
    margin: 0 5rem;
    font-size: 60px;
  }
}
.col {
  display: flex;
  flex-direction: column;
}
