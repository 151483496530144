.card-wrapper {
  width: 328px;
  height: 200px;
  margin: 1rem auto;
  position: relative;
  display: block;
  border-radius: 1rem;
  box-shadow: $default-shadow;
  overflow: hidden;
  cursor: pointer;

  @include breakpoint-up("medium") {
    width: 352px;
  }
  .card-img {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 100%;
    }
  }
  .card-info {
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-flow: column;
    gap: 0.25rem;
    color: $white;
    background-color: $bg-blur;

    .card-title {
      margin: 0;
      padding: 0;
      font-size: 14px;
      overflow: hidden;
    }
    .card-description {
      margin: 0;
      padding: 0;
      font-size: 12px;
      overflow: hidden;
    }
  }
}
