// 640px, 1150px, 1400px
$breakpoints-up: (
  "medium": "48em",
  "large": "71.875em",
  "xlarge": "87.5em",
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoints-up, $size)) {
    @content;
  }
}
