.menu-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $white;
  z-index: 100;

  .menu-list {
    width: 100%;
    padding: 0;
    position: sticky;
    top: 50%;
    left: 50%;
    transform: translate(0, -50%);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    list-style: none;

    .menu-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .menu-link {
        width: 100%;
        font-family: $font-primary;
        font-size: 14px;
        text-align: center;
        color: $black;
        font-weight: 500;
        cursor: pointer;
        transition: $t-150;

        @include breakpoint-up("medium") {
          font-size: 20px;
        }
        @include breakpoint-up("large") {
          font-size: 28px;
        }

        &:hover {
          color: $lightBlue;
        }
      }
    }
  }
}
