$white: #ffffff;
$softGray: #f1f1f1;
$gray: #cccccc;
$darkGray: #666666;
$softBlack: #222222;
$black: #000000;
$salmon: #ff8d8a;
$lightBlue: #5ccfd3;
$softGreen: #5cd3bf;
$orange: #f1c18f;

$disabled: #ababab;

$bg-blur: hsla(0, 0%, 100%, 0.25);

$default-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
