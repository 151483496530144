@charset "UTF-8";
html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
}
body a {
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.w-100 {
  width: 100% !important;
}

.bg-lightBlue {
  background: linear-gradient(to bottom, transparent, transparent 80%, #5ccfd3 80%, #5ccfd3 100%);
}
@media (min-width: 71.875em) {
  .bg-lightBlue.bg-large {
    background: linear-gradient(to bottom, transparent, transparent 40%, #5ccfd3 40%, #5ccfd3 100%);
  }
}

.text-lightBlue, .link-active a, .link-active {
  color: #5ccfd3 !important;
}

.text-disabled {
  color: #ababab !important;
}

.no-scroll {
  overflow: hidden !important;
}

.title-description {
  max-width: 500px;
  margin: 0 2.5rem;
  font-size: 14px;
  font-weight: 500;
}
@media (min-width: 48em) {
  .title-description {
    font-size: 16px;
  }
}
@media (min-width: 71.875em) {
  .title-description {
    margin: 0 5rem;
    font-size: 28px;
  }
}

.title-header {
  max-width: 500px;
  margin: 0 2.5rem;
  font-weight: 700;
  font-size: 2rem;
}
@media (min-width: 48em) {
  .title-header {
    font-size: 40px;
  }
}
@media (min-width: 71.875em) {
  .title-header {
    margin: 0 5rem;
    font-size: 60px;
  }
}

.col {
  display: flex;
  flex-direction: column;
}

.container-grid {
  width: 100%;
  position: relative;
}
.container-grid .container-grid-inner {
  width: 100%;
  max-width: 100vw;
  min-height: 450px;
  height: 70vh;
  margin-top: 0.5rem;
  padding-bottom: 5vh;
  box-sizing: content-box;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.container-grid .container-grid-inner::-webkit-scrollbar {
  display: none;
}
@media (min-width: 48em) {
  .container-grid .container-grid-inner {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (min-width: 71.875em) {
  .container-grid .container-grid-inner {
    max-width: 1200px !important;
    margin: auto !important;
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
.container-grid .container-grid-inner.beneficio {
  min-height: 200px;
  height: 50vh;
  max-height: 350px;
  margin: auto;
  display: flex;
  flex-flow: column;
}
@media (min-width: 48em) {
  .container-grid .container-grid-inner.beneficio {
    height: 500px;
    max-height: 500px !important;
    margin: 5rem auto !important;
    padding-bottom: 0;
  }
}
@media (min-width: 71.875em) {
  .container-grid .container-grid-inner.beneficio {
    margin: 5rem auto !important;
  }
}

.slide-container {
  position: relative;
}
.slide-container .slide-container-inner {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  box-sizing: content-box;
  overflow-y: visible;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
@media (min-width: 48em) {
  .slide-container .slide-container-inner {
    pading: 3rem 1.5rem;
  }
}
@media (min-width: 71.875em) {
  .slide-container .slide-container-inner {
    padding: 3rem 5rem;
  }
}
.slide-container .slide-container-inner::-webkit-scrollbar {
  width: 0 !important;
}

.form-container {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-container {
  width: 80%;
  max-width: 600px;
  margin: 2rem auto;
}
@media (min-width: 48em) {
  .content-container {
    margin-top: 5rem;
  }
}
.content-container .content-title {
  font-weight: 700;
  font-size: 2rem;
}
.content-container .content-text {
  font-size: 14px;
  line-height: 1.75;
}

.btn {
  display: block;
  cursor: pointer;
}

.btn-primary, .btn-mood {
  width: 300px;
  height: 48px;
  margin: 1rem auto;
  background-color: #5ccfd3;
  border: none;
  border-radius: 0.5rem;
  transition: 150ms all ease-in-out !important;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.btn-primary a, .btn-mood a {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.btn-primary:hover, .btn-mood:hover {
  background-color: #42bec2;
}

.btn-nav {
  background-color: transparent;
  border: none;
  transition: 150ms all ease-in-out !important;
}
.btn-nav:hover svg {
  width: 100%;
  height: 100%;
}
.btn-nav:hover svg g line,
.btn-nav:hover svg g path {
  stroke: #5ccfd3;
  fill: #5ccfd3;
}

.btn-slide-container {
  width: 48px;
  height: 96px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  background-color: #ffffff;
  color: #5ccfd3;
  font-size: 12px;
  font-weight: 700;
  border: none;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}
@media (min-width: 48em) {
  .btn-slide-container {
    height: 48px;
    transform: none;
    position: relative;
    background-color: #5ccfd3;
    border-radius: 0.5rem !important;
    box-shadow: 0px 0px 4px 0px #5ccfd3;
    color: #ffffff;
    transition: 150ms all ease-in-out !important;
  }
  .btn-slide-container:hover {
    background-color: #ffffff;
    color: #5ccfd3;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
}
.btn-slide-container.left {
  left: 0;
  border-radius: 0 0.5rem 0.5rem 0;
}
.btn-slide-container.right {
  right: 0;
  border-radius: 0.5rem 0 0 0.5rem;
}

.btn-share {
  width: 296px;
  height: 48px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  transition: 150ms all ease-in-out !important;
}
.btn-share:hover {
  transform: scale(0.95);
}
.btn-share.twitter {
  background-color: #0da1f2;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}
.btn-share.facebook {
  background-color: #4267b2;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
}

.beneficio-buttons-wrapper, .mood-buttons-wrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}
@media (min-width: 48em) {
  .beneficio-buttons-wrapper, .mood-buttons-wrapper {
    flex-flow: row-reverse;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 1.5rem;
    right: 3rem;
  }
}

.btn-beneficio {
  width: 328px;
  height: 48px;
  margin: 0 auto 1rem auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #5ccfd3;
  background-color: white;
  border: none;
  border-radius: 0.5rem;
  transition: 150ms all ease-in-out !important;
}
.btn-beneficio a {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #5ccfd3;
}
.btn-beneficio:hover {
  background-color: #42bec2;
  color: #ffffff;
  border: 1px solid #42bec2;
}
.btn-beneficio:hover a {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
@media (min-width: 48em) {
  .btn-beneficio {
    margin: 0;
    background-color: #5ccfd3;
    border-radius: 0.5rem;
    color: #ffffff;
  }
  .btn-beneficio a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
  }
  .btn-beneficio:hover:hover {
    background-color: #ffffff;
    color: #5ccfd3;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .btn-beneficio:hover:hover a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #5ccfd3;
  }
}

.btn-beneficio-scroll {
  width: 48px;
  height: 48px;
  background-color: transparent;
  border: none;
  transition: 150ms all ease-in-out !important;
}
.btn-beneficio-scroll:hover {
  color: #5ccfd3;
}
@media (min-width: 48em) {
  .btn-beneficio-scroll {
    background-color: #5ccfd3;
    border-radius: 0.5rem;
    color: white;
  }
  .btn-beneficio-scroll:hover {
    background-color: #ffffff;
    color: #5ccfd3;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 48em) {
  .btn-mood {
    margin: 0;
  }
  .btn-mood:hover {
    background-color: #ffffff;
    color: #5ccfd3;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
  }
  .btn-mood:hover a {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #5ccfd3;
  }
}

.card-wrapper {
  width: 328px;
  height: 200px;
  margin: 1rem auto;
  position: relative;
  display: block;
  border-radius: 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
}
@media (min-width: 48em) {
  .card-wrapper {
    width: 352px;
  }
}
.card-wrapper .card-img {
  width: 100%;
  height: 100%;
}
.card-wrapper .card-img img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.card-wrapper .card-info {
  padding: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column;
  gap: 0.25rem;
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.25);
}
.card-wrapper .card-info .card-title {
  margin: 0;
  padding: 0;
  font-size: 14px;
  overflow: hidden;
}
.card-wrapper .card-info .card-description {
  margin: 0;
  padding: 0;
  font-size: 12px;
  overflow: hidden;
}

.product-card {
  min-width: 300px;
  width: 80%;
  max-width: 350px;
  height: 375px;
  cursor: pointer;
}
@media (min-width: 48em) {
  .product-card {
    max-width: 400px;
    height: 450px;
  }
}
@media (min-width: 71.875em) {
  .product-card {
    max-width: 450px;
    height: 550px;
  }
}

.product-wrapper, .product-form .form-product-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  overflow: hidden;
}
.product-wrapper .slider-card-img, .product-form .form-product-wrapper .slider-card-img {
  width: 100%;
  height: 100%;
  display: block;
  overflow: hidden;
}
.product-wrapper .slider-card-img img, .product-form .form-product-wrapper .slider-card-img img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.product-wrapper .slider-card-info, .product-form .form-product-wrapper .slider-card-info {
  width: 100%;
  min-height: 100px;
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.25);
}
.product-wrapper .slider-card-info h2, .product-form .form-product-wrapper .slider-card-info h2 {
  width: 35%;
  margin: 1rem 0 0.5rem 1.5rem;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5rem;
  color: #ffffff;
}

@media (min-width: 48em) {
  .product-form {
    width: 90%;
    max-width: 800px;
    margin: auto;
  }
}
.product-form .form-control {
  width: 100%;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.product-form .form-control input {
  width: 100%;
  height: 48px;
  padding-left: 1rem;
  font-family: "Poppins", sans-serif;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
}
.product-form .form-control input:placeholder {
  font-family: "Poppins", sans-serif;
  color: #ababab;
}
.product-form .form-control input:focus,
.product-form .form-control input :focus-visible {
  outline: none;
}
.product-form .form-control input:visited {
  color: #5ccfd3;
}
.product-form .form-product-wrapper {
  box-shadow: none;
  padding: 1rem 0;
  border-radius: 0;
  gap: 1rem;
}
@media (min-width: 48em) {
  .product-form .form-product-wrapper {
    flex-flow: row wrap;
    gap: 2rem;
  }
}
.product-form .form-product-wrapper .product-image {
  width: 90%;
  margin: auto;
  border-radius: 1rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
}
.product-form .form-product-wrapper .product-title {
  width: 150px;
  height: auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}
.product-form .form-product-wrapper .product-title .product-title-description {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}
.product-form .form-product-wrapper .product-title .product-title-header {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
}
.product-form .form-product-wrapper .product-list {
  margin: 0;
  padding: 0;
}
@media (min-width: 48em) {
  .product-form .form-product-wrapper .product-list {
    margin-top: auto;
  }
}
.product-form .form-product-wrapper .product-list .product-item-title {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 500;
}
.product-form .form-product-wrapper .product-list .product-list-item {
  list-style: none;
}
.product-form .form-product-wrapper .product-list .product-list-item.value {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.price-card {
  min-width: 220px;
  width: 50%;
  max-width: 305px;
  height: 390px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;
}
@media (min-width: 48em) {
  .price-card {
    max-width: 360px;
    height: 480px;
    margin-left: 5rem;
  }
}
.price-card:last-of-type {
  margin-right: 1.5rem;
}
@media (min-width: 71.875em) {
  .price-card:last-of-type {
    margin-right: 2rem;
  }
}
.price-card .price-card-image {
  width: 90%;
  height: 80%;
  margin: auto;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
}
.price-card .price-card-image img {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}
.price-card .price-card-value {
  width: 200px;
  height: 40px;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-family: "Poppins", sans-serif;
}
.price-card .price-card-value .control-wrapper {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
}
@media (min-width: 48em) {
  .price-card .price-card-value .control-wrapper {
    font-size: 18px;
  }
}
.price-card .price-card-value .control-wrapper .card-label {
  color: #5ccfd3;
  font-size: 16px;
}
.price-card .price-card-value .control-wrapper .card-input {
  width: 14px;
  height: 14px;
  left: -30px;
  top: 7px;
  position: absolute;
}
.price-card .price-card-value .control-wrapper .card-input:after {
  content: " ";
  width: 14px;
  height: 14px;
  display: flex;
  background-color: #ffffff;
  color: #5ccfd3;
  font-weight: 700;
  border: 2px solid #5ccfd3;
}
.price-card .price-card-value .control-wrapper .card-input:checked:after {
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  content: "✓";
  background-color: #ffffff;
  border: 2px solid #5ccfd3;
}
.price-card .price-card-value .value-wrapper {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.value-money,
.value-number {
  font-size: 16px;
}
@media (min-width: 48em) {
  .value-money,
.value-number {
    font-size: 18px;
  }
}

.value-subnumber {
  font-size: 8px;
}
@media (min-width: 48em) {
  .value-subnumber {
    font-size: 10px;
  }
}

.beneficio-card {
  min-width: 300px;
  width: 80%;
  max-width: 328px;
  height: 64px;
  margin: 1rem auto;
  padding-left: 2rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.beneficio-card:hover {
  background-color: #5ccfd3;
}
.beneficio-card:hover a {
  color: #ffffff;
  font-weight: 700;
}
.beneficio-card a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 2rem;
  text-decoration: none;
  color: #000000;
  transition: 150ms all ease-in-out !important;
}

nav {
  width: 100%;
  height: 100px;
  padding: 2rem;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 999999;
}
@media (min-width: 48em) {
  nav {
    padding: 3rem 5rem;
    height: 125px;
  }
}
nav .nav-left img {
  width: 100px;
  height: auto;
}
nav .nav-left img:hover {
  color: #5ccfd3;
}
@media (min-width: 48em) {
  nav .nav-left img {
    height: 125px;
  }
}
@media (min-width: 71.875em) {
  nav .nav-left img {
    width: 180px;
  }
}
nav .nav-right .btn-navs-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  align-self: center;
}

.menu-container, .share-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 100;
}
.menu-container .menu-list, .share-container .menu-list, .share-container .share-list {
  width: 100%;
  padding: 0;
  position: sticky;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  list-style: none;
}
.menu-container .menu-list .menu-item, .share-container .menu-list .menu-item, .share-container .share-list .menu-item {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-container .menu-list .menu-item .menu-link, .share-container .menu-list .menu-item .menu-link, .share-container .share-list .menu-item .menu-link {
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  text-align: center;
  color: #000000;
  font-weight: 500;
  cursor: pointer;
  transition: 150ms all ease-in-out !important;
}
@media (min-width: 48em) {
  .menu-container .menu-list .menu-item .menu-link, .share-container .menu-list .menu-item .menu-link, .share-container .share-list .menu-item .menu-link {
    font-size: 20px;
  }
}
@media (min-width: 71.875em) {
  .menu-container .menu-list .menu-item .menu-link, .share-container .menu-list .menu-item .menu-link, .share-container .share-list .menu-item .menu-link {
    font-size: 28px;
  }
}
.menu-container .menu-list .menu-item .menu-link:hover, .share-container .menu-list .menu-item .menu-link:hover, .share-container .share-list .menu-item .menu-link:hover {
  color: #5ccfd3;
}