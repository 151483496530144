.slide-container {
  position: relative;

  .slide-container-inner {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    box-sizing: content-box;
    overflow-y: visible;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scrollbar-width: none;

    @include breakpoint-up("medium") {
      pading: 3rem 1.5rem;
    }

    @include breakpoint-up("large") {
      padding: 3rem 5rem;
    }

    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
