.price-card {
  min-width: 220px;
  width: 50%;
  max-width: 305px;
  height: 390px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: hidden;

  @include breakpoint-up("medium") {
    max-width: 360px;
    height: 480px;
    margin-left: 5rem;
  }

  &:last-of-type {
    margin-right: 1.5rem;

    @include breakpoint-up("large") {
      margin-right: 2rem;
    }
  }

  .price-card-image {
    width: 90%;
    height: 80%;
    margin: auto;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: $default-shadow;

    img {
      object-fit: cover;
      object-position: top center;
      width: 100%;
      height: 100%;
    }
  }
  .price-card-value {
    width: 200px;
    height: 40px;
    margin: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-family: $font-primary;

    .control-wrapper {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 14px;
      @include breakpoint-up("medium") {
        font-size: 18px;
      }

      .card-label {
        color: $lightBlue;
        font-size: 16px;
      }
      .card-input {
        width: 14px;
        height: 14px;
        left: -30px;
        top: 7px;
        position: absolute;

        &:after {
          content: " ";
          width: 14px;
          height: 14px;
          display: flex;
          background-color: $white;
          color: $lightBlue;
          font-weight: 700;
          border: 2px solid $lightBlue;
        }
        &:checked {
          &:after {
            width: 14px;
            height: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            content: "✓";
            background-color: $white;
            border: 2px solid $lightBlue;
          }
        }
      }
    }
    .value-wrapper {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}
.value-money,
.value-number {
  font-size: 16px;

  @include breakpoint-up("medium") {
    font-size: 18px;
  }
}
.value-subnumber {
  font-size: 8px;
  @include breakpoint-up("medium") {
    font-size: 10px;
  }
}
