html {
  font-size: 100%;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: $font-primary;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;

  a {
    text-decoration: none;
  }
}
