//Sample component
nav {
  width: 100%;
  height: 100px;
  padding: 2rem;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  z-index: 999999;

  @include breakpoint-up("medium") {
    padding: 3rem 5rem;
    height: 125px;
  }

  .nav-left {
    img {
      width: 100px;
      height: auto;
      &:hover {
        color: $lightBlue;
      }

      @include breakpoint-up("medium") {
        height: 125px;
      }

      @include breakpoint-up("large") {
        width: 180px;
      }
    }
  }

  .nav-right {
    .btn-navs-wrapper {
      display: flex;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: center;
      align-self: center;
    }
  }
}
