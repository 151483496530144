.product-form {
  @include breakpoint-up("medium") {
    width: 90%;
    max-width: 800px;
    margin: auto;
  }
  .form-control {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    input {
      width: 100%;
      height: 48px;
      padding-left: 1rem;
      font-family: $font-primary;
      color: $black;
      font-size: 14px;
      font-weight: 500;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0.5rem;
      box-shadow: $default-shadow;

      &:placeholder {
        font-family: $font-primary;
        color: $disabled;
      }
      &:focus,
      :focus-visible {
        outline: none;
      }
      &:visited {
        color: $lightBlue;
      }
    }
  }
  .form-product-wrapper {
    @extend .product-wrapper;
    box-shadow: none;
    padding: 1rem 0;
    border-radius: 0;
    gap: 1rem;
    @include breakpoint-up("medium") {
      flex-flow: row wrap;
      gap: 2rem;
    }
    .product-image {
      width: 90%;
      margin: auto;
      border-radius: 1rem;
      box-shadow: $default-shadow;
    }
    .product-title {
      width: 150px;
      height: auto;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      align-items: flex-start;

      .product-title-description {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;
      }
      .product-title-header {
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 500;
      }
    }
    .product-list {
      margin: 0;
      padding: 0;
      @include breakpoint-up("medium") {
        margin-top: auto;
      }
      .product-item-title {
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 500;
      }
      .product-list-item {
        list-style: none;

        &.value {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
