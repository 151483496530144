.beneficio-card {
  min-width: 300px;
  width: 80%;
  max-width: 328px;
  height: 64px;
  margin: 1rem auto;
  padding-left: 2rem;
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    background-color: $lightBlue;

    a {
      color: $white;
      font-weight: 700;
    }
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 2rem;
    text-decoration: none;
    color: $black;
    transition: $t-150;
  }
}
